html {
  background-color: #F5F5FB !important;
}

body {
  height: unset !important;
}

input[type="checkbox"]:checked::before {
  background-color: #5E81F4;
}

.card {
  min-height: 85vh !important;
  max-height: 90vh !important;
}

.main__content .card {
  min-height: 95vh !important;
}

.card-body {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.card-body::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border: 1px solid #fcfcfc;
}
