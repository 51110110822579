:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

html {
  background-color: #F5F5FB;
}

body {
  background: #F5F5FB;
  font-family: 'Roboto';
  text-align: center;
  height: 100vh;
}

#auth__flow__wrapper__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#auth__flow__wrapper__container .link-btn-container {
  text-align: center !important;
  vertical-align: middle;
  color: #ffffff;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  border: 0px;
  border-radius: 6px;
  font-size: 14px;
}

#auth__flow__wrapper__container .card {
  width: 445px;
  border: 0;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: height 1s ease;
  transition: height 1s ease;
  margin: 50px;
}

#auth__flow__wrapper__container .card .card-title #menu__tabs__wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Roboto';
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #FFFFFF;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  border: 5px solid #FFFFFF;
}

#auth__flow__wrapper__container .card .card-title #menu__tabs__wrapper .menu__tab {
  width: 50%;
  padding: 8px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  border-bottom: 3px solid #FFFFFF;
}

#auth__flow__wrapper__container .card .card-title #menu__tabs__wrapper .menu__tab.active {
  border-bottom: 3px solid #5E81F4;
  color: #5E81F4;
  font-weight: 500;
}

#auth__flow__wrapper__container .card .card-body #log-in-prompt {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

#auth__flow__wrapper__container .card .card-body #welcome {
  color: #25262B;
  font-family: 'Oswald';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

#auth__flow__wrapper__container .card .card-body .tab__body__content {
  -webkit-transition: display 10s ease-in-out;
  transition: display 10s ease-in-out;
}
