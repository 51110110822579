#inline__alert__wrapper{
    // position: fixed;
    // width: 100%;
    // display: grid;
    // justify-content: center;
    // justify-items: center;
    // z-index: 999999;
    .inline__alert{
        color: #fff;
        background-color: #e74c3c;
        padding: 8px;
        border-radius: 4px;
        overflow: hidden;
        font-family: sans-serif;
        cursor: pointer;
        margin: 3px 10px;
        display: flex;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
        
        .icon__span{
            margin-left: 15px;
        }
        
        .message__span{
            width: 100%;
            text-align: center;
            direction: ltr;
        }

        .close__btn{
            margin-right: 15px;
            color: white;
            font-weight: bold;
            float: right;
            font-size: 22px;
            line-height: 20px;
            cursor: pointer;
            transition: 0.3s;
            :hover{
                color: #e4b4af;
            }
        }
    }
}