.invitation__modal .invitation__header {
  border-bottom: none !important;
  font-family: 'Oswald';
}

.invitation__modal .modal__body {
  text-align: left;
  font-family: 'Roboto';
}

.invitation__modal .invitation__footer {
  border-top: none !important;
  font-family: 'Roboto';
}

.invitation__modal .invitation__footer #button__accept {
  margin-left: 10px;
  background-color: #5D81F4;
  border: 1px solid #5D81F4;
}
