$tap-colors: (
    primary: #5E81F4,
    accent: #20BDCB,
    background: #F5F5FB,
    form-input: #FFF5DB,
    form-input-border: #E6E7EE,
    dark-text: #25262B,
    nav-icon: #b7b8c1,
    search-input: #F5F5FB,
    gray-btn: #F5F5FB,
    search-text: #9EA5B2,
    notification-badge: #F1682C,
    option-color: #212529
);

$round-button-font-size: 2rem;
$text-background: #0085ff;
$editing-outline: #adb5bd;
$divider: #c5c7d0;
$search-field-height: 56px;
$white: #fff;
$cyan:  #17a2b8 !default;
$info:  $cyan !default;
$gray-700: #495057 !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
$primary:       #5e72e4 !default;

$dark:          $gray-900 !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;


$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

// No UI Slider

$noui-target-bg:                  #eceeef !default;
$noui-target-thickness:           5px !default;
$noui-target-border-radius:       5px !default;
$noui-target-border-color:        0 !default;
$noui-target-box-shadow:          inset 0 1px 2px rgba(90,97,105,.1) !default;

$noui-slider-connect-bg:          $primary !default;
$noui-slider-connect-disabled-bg: #b2b2b2 !default;

$noui-handle-width:               15px !default;
$noui-handle-bg:                  theme-color("primary") !default;
$noui-handle-border:              0 !default;
$noui-handle-border-radius:       100% !default;

$noui-origin-border-radius:       2px !default;


// Dropdown

$dropdown-bg:                    $white !default;
$dropdown-border-width:          0 !default;
$dropdown-border-color:          rgba($black, .15) !default;
$dropdown-border-radius:         $border-radius-lg !default;
$dropdown-box-shadow:            0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1) !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.5);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);
$font-size-xs:                ($font-size-base * .75);