#confirm__email__container .instruction__text {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

#confirm__email__container #btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#confirm__email__container #btn-row .btn-resend {
  border: 1px solid #5d81f4  !important;
  background-color: transparent !important;
  color: #5d81f4 !important;
}

#confirm__email__container #btn-row .btn-next {
  background-color: #5d81f4 !important;
  border: 0px;
  color: #ffffff !important;
}

#confirm__email__container #btn-row .form-btn {
  text-align: center !important;
  vertical-align: middle;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 14px;
  border-radius: 6px;
}
