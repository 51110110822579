
#payment__plans__container{
    .row{
        margin: 0;
    }
    .form-btn{
        text-align: center !important;
        vertical-align: middle;
        width: 100%;
        padding-top: 17px;
        padding-bottom: 17px;
        font-size: 14px;
        border-radius: 18px;
        color: #ffffff;
        background-color: #5d81f4;
    }
}