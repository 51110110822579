#oauth-login-container{
    //display: flex;
    .btn-oauth{
        display: flex;
        border: 2px solid #E6E7EE;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 6px;
        
        width: 100%;
        margin-bottom: 1em;
        img{
            width: 20px;
            margin: 0 0 0 20px;
        }
        .btn-text{
            text-align: center;
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            margin-right: 40px;
        }
    }
}