@import "../../../assets/scss/functions";
@import "../../../assets/scss/variables";
    
:root {
--input-padding-x: 1.5rem;
--input-padding-y: .75rem;
}

html{
    background-color: color('background');
}
  
body {
    background:  color('background');
    font-family: 'Roboto';
    text-align: center;
    height: 100vh;
}

#auth__flow__wrapper__container{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    .link-btn-container{
        text-align: center !important;
        vertical-align: middle;
        color: #ffffff;
        width: 100%;
        padding-top: 17px;
        padding-bottom: 17px;
        border: 0px;
        border-radius: 6px;
        font-size: 14px;
    }   

    .card{
        width: 445px;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
        transition: height 1s ease; 
        margin: 50px;
        

        .card-title{
            #menu__tabs__wrapper{
                width: 100%;
                display: flex;
                font-family: 'Roboto';
                align-items: center;
                justify-content: center;
                background-color: #FFFFFF;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
                box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
                border: 5px solid #FFFFFF;
                
                .menu__tab{
                    width: 50%;
                    padding: 8px;
                    cursor: pointer;
                    text-align: center;
                    font-weight: 500;
                    border-bottom: 3px solid #FFFFFF;
                }

                .menu__tab.active{
                    border-bottom: 3px solid #5E81F4;
                    color: #5E81F4;
                    font-weight: 500;
                }
            }
        }
        
        .card-body {
            #log-in-prompt{
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                width: 100%;
                text-align: center;
              }
              
              #welcome{
                color: #25262B;
                font-family: 'Oswald';
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 48px;
                text-align: center;
              }

              .tab__body__content{
                  transition: display 10s ease-in-out;
              }
        }
    }
    
    
}