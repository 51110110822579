#signup__form__container form {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  font-family: 'Roboto';
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}

#signup__form__container .form-control {
  padding: 1.5rem 1rem !important;
}

#signup__form__container .form-input {
  border: 2px solid #E6E7EE;
  background-color: #FEF5DB;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 6px;
  font-size: 14px;
}

#signup__form__container .form-input::-webkit-input-placeholder {
  color: #b1b1b3a1 !important;
}

#signup__form__container .form-input:-ms-input-placeholder {
  color: #b1b1b3a1 !important;
}

#signup__form__container .form-input::-ms-input-placeholder {
  color: #b1b1b3a1 !important;
}

#signup__form__container .form-input::placeholder {
  color: #b1b1b3a1 !important;
}

#signup__form__container .btn-signup {
  background-color: #5E81F4;
  border-color: #5E81F4;
  text-align: center !important;
  vertical-align: middle;
  color: #ffffff;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  border: 0px;
  border-radius: 18px;
  font-size: 14px;
}
