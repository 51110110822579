#email__login__container .form-control {
  padding: 1.5rem 1rem !important;
}

#email__login__container .form-input {
  border: 2px solid #E6E7EE;
  background-color: #FEF5DB;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 6px;
  font-size: 14px;
}

#email__login__container .btn-login {
  background-color: #5E81F4;
  border-color: #5E81F4;
  text-align: center !important;
  vertical-align: middle;
  color: #ffffff;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  border: 0px;
  border-radius: 6px;
  font-size: 14px;
}

#email__login__container form {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  font-family: 'Roboto';
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}

#email__login__container form #forgot-password {
  color: #000;
  -webkit-text-decoration: underline red;
          text-decoration: underline red;
  text-decoration-thickness: 0.2em;
}
