@import './assets/scss/functions';
@import './assets/scss/mixins';
@import './assets/scss/variables';

html{
    background-color: color('background') !important;
}
body{
    height: unset !important;
}

input[type="checkbox"]{
    &:checked{
        &::before{
            background-color: color('primary');
        }
    }
}

.card{
    min-height: 85vh !important;
    max-height: 90vh !important;
}

.main__content{
    .card{
        min-height: 95vh !important;
    }
}

.card-body{

    display: flex !important;
    flex-direction: column !important;

    &::-webkit-scrollbar{
        width: 5px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #cccccc;
        border: 1px solid #fcfcfc;
    }
}