:root{
    --rbcolor: #5d81f4;
}
.payment__plan__container{
    .card{
        // border: 1px solid #A0B4F9;
        min-height: unset !important;
        max-height: unset !important;
        border-radius: 10px;
        text-align: left;
        cursor: pointer;
        
        .card-body{
            padding: 25px;
            padding-bottom: 0px;

            #plan-name{
                font-size: 20px;
                font-weight: bold;
                color:  #25262B;
            }

            #plan-cost{
                color: #0D6EFD;
                font-size: 20px;
                font-weight: bold;
            }

            ul{
                list-style: none;
                padding-left: 3px;
                li{
                    line-height: 1.8em;
                    &::before{
                        content: '✓';
                        margin-right: 5px;
                        color: #83dae2;
                        font-weight: 700;
                    }
                }
            }
            //Custom Radio Button
            .radio {
                font-size: 1rem;
                color: var(--rbcolor);
                display: grid;
                grid-template-columns: min-content auto;
                grid-gap: 0.5em;
            }
      
            .radio__input {
                display: flex;
                input {
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked + .radio__control::before {
                        transform: scale(1);
                    }
                }
      
            }
            
            .radio__control {
                display: block;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                border: 0.1em solid var(--rbcolor);
      
                transform: translateY(-0.05em);
      
                display:grid;
                place-items: center;
            }
      
            .radio__label {
                line-height: 1;
            }
            
            input + .radio__control::before {
                content: "";
                width: .5em;
                height: .5em;
                box-shadow: inset .5em .5em var(--rbcolor);
                border-radius: 50%;
                transition: 180ms transform ease-in-out;
                transform: scale(0);    
            }
        }
    }

    .active__payment__plan{
        border: 1px solid #A0B4F9;
    }
}