.signup-card-wrapper {
  border: 0;
  background-color: white;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.signup-card-wrapper .card-body {
  padding: 2rem;
  font-family: 'Roboto';
}

.signup-card-wrapper #title {
  color: #25262B;
  font-family: 'Oswald';
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  width: 100%;
}

.signup-card-wrapper .sub-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: center;
}
