#inline__alert__wrapper .inline__alert {
  color: #fff;
  background-color: #e74c3c;
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  margin: 3px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

#inline__alert__wrapper .inline__alert .icon__span {
  margin-left: 15px;
}

#inline__alert__wrapper .inline__alert .message__span {
  width: 100%;
  text-align: center;
  direction: ltr;
}

#inline__alert__wrapper .inline__alert .close__btn {
  margin-right: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#inline__alert__wrapper .inline__alert .close__btn :hover {
  color: #e4b4af;
}
