@charset "UTF-8";
:root {
  --rbcolor: #5d81f4;
}

.payment__plan__container .card {
  min-height: unset !important;
  max-height: unset !important;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
}

.payment__plan__container .card .card-body {
  padding: 25px;
  padding-bottom: 0px;
}

.payment__plan__container .card .card-body #plan-name {
  font-size: 20px;
  font-weight: bold;
  color: #25262B;
}

.payment__plan__container .card .card-body #plan-cost {
  color: #0D6EFD;
  font-size: 20px;
  font-weight: bold;
}

.payment__plan__container .card .card-body ul {
  list-style: none;
  padding-left: 3px;
}

.payment__plan__container .card .card-body ul li {
  line-height: 1.8em;
}

.payment__plan__container .card .card-body ul li::before {
  content: '✓';
  margin-right: 5px;
  color: #83dae2;
  font-weight: 700;
}

.payment__plan__container .card .card-body .radio {
  font-size: 1rem;
  color: var(--rbcolor);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: min-content auto;
      grid-template-columns: -webkit-min-content auto;
      grid-template-columns: min-content auto;
  grid-gap: 0.5em;
}

.payment__plan__container .card .card-body .radio__input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment__plan__container .card .card-body .radio__input input {
  opacity: 0;
  width: 0;
  height: 0;
}

.payment__plan__container .card .card-body .radio__input input:checked + .radio__control::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.payment__plan__container .card .card-body .radio__control {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid var(--rbcolor);
  -webkit-transform: translateY(-0.05em);
          transform: translateY(-0.05em);
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.payment__plan__container .card .card-body .radio__label {
  line-height: 1;
}

.payment__plan__container .card .card-body input + .radio__control::before {
  content: "";
  width: .5em;
  height: .5em;
  -webkit-box-shadow: inset 0.5em 0.5em var(--rbcolor);
          box-shadow: inset 0.5em 0.5em var(--rbcolor);
  border-radius: 50%;
  -webkit-transition: 180ms -webkit-transform ease-in-out;
  transition: 180ms -webkit-transform ease-in-out;
  transition: 180ms transform ease-in-out;
  transition: 180ms transform ease-in-out, 180ms -webkit-transform ease-in-out;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.payment__plan__container .active__payment__plan {
  border: 1px solid #A0B4F9;
}
