#floating__logo__wrapper {
  cursor: pointer;
}

#floating__logo__wrapper #floating__logo {
  display: -ms-grid;
  display: grid;
  justify-items: center;
  padding: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  bottom: 3vh;
  left: 5vw;
  z-index: 3;
}

#floating__logo__wrapper #floating__logo img {
  width: 92.9px;
  height: 92.9px;
}

#floating__logo__wrapper #floating__logo span {
  height: 40px;
  color: #25262B;
  font-family: Oswald;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
}
