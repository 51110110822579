@import "../../../assets/scss/functions";
@import "../../../assets/scss/variables";

#signup__form__container{
    form{
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        font-family: 'Roboto';
        text-decoration-color: black;
    
        // #forgot-password{
        //     color: #000;
        //     text-decoration: underline red;
        //     text-decoration-thickness: 0.2em;
        // }
    }

    .form-control {
        padding: 1.5rem 1rem !important;
    }
    
    .form-input{
        border: 2px solid #E6E7EE;
        background-color: #FEF5DB;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 6px;
        font-size: 14px;
        &::placeholder{
            color: #b1b1b3a1 !important;
        }
    }

    .btn-signup{
        background-color: color('primary');
        border-color: color('primary');
        text-align: center !important;
        vertical-align: middle;
        color: #ffffff;
        width: 100%;
        padding-top: 17px;
        padding-bottom: 17px;
        border: 0px;
        border-radius: 18px;
        font-size: 14px;
    }
}