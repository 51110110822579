#floating__logo__wrapper {
    cursor: pointer;
    //FLOATING LOGO
    #floating__logo{
        display: grid;
        justify-items: center;
        padding: 3px;
        width: fit-content;
        
        position: fixed;
        bottom: 3vh;
        left: 5vw;
        z-index: 3;

        img{
            width: 92.9px;
            height: 92.9px;
            //filter: grayscale(100%);
        }

        span{
            height: 40px;
            color: #25262B;
            font-family: Oswald;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
            text-align: center;
        }
    }
}